import React, { useContext, useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { EmailContext } from '../context/EmailContext';
import { FaUpload } from 'react-icons/fa';


export default function AddBulkEmails() {
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState(null);
  const [fileName, setFileName] = useState('');
  const { addBulkEmail } = useContext(EmailContext);
  const fileInputRef = useRef(null); // Reference to the hidden file input

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const emailList = jsonData.flat().filter((email) => validateEmail(email));

      if (emailList.length > 0) {
        setEmails(emailList);
        setError(null);
      } else {
        setError('No valid email addresses found in the uploaded file.');
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = () => {
    if (emails.length === 0) {
      setError('Please upload a file with valid email addresses before submitting.');
      return;
    }
    addBulkEmail(emails);

    // Clear the input and reset the state after the process is complete
    setEmails([]);
    setFileName('');
    setError(null);
  };

  // Trigger file input click when upload button is clicked
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'fit-content' }}>
      <div style={{ display: 'flex', maxWidth: '400px', width: '100%' }}>
        <button
          onClick={handleUploadClick}
          className="btn btn-primary btn-sm"
          title='Upload excell file'
        >
          <FaUpload />
        </button>
        {fileName && (
          <button
            onClick={handleSubmit}
            className="btn btn-primary"
            style={{
              padding: '10px 20px',
              borderRadius: '5px',
              backgroundColor: '#007bff',
              borderColor: '#007bff',
              color: '#fff',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            Submit
          </button>
        )}
        <input
          type="file"
          accept=".xlsx, .xls"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: 'none' }} // Hidden input field
        />
      </div>
      {error && (
        <div style={{ color: 'red', marginTop: '10px', border: '1px solid orange' }}>
          {error}
        </div>
      )}
    </div>
  );
}
