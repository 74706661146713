import React, {useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import ImageViewer from '../../utils/ImageViewer';
import {TemplateOneContext} from './TemplateOneContext'

const Preview = () => {
const {instance} = useContext(TemplateOneContext)

  // Sample image URL for demonstration
  const logo = 'https://beshafricanadventures.co.ke/wp-content/uploads/2024/05/Besh-Logo-Transparent-01-e1716311550879.png'

  // ==================== IMAGES VIEWER =====================
  const images=[instance?.image]
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [scale, setScale] = useState(1);
  // isOpen, setIsOpen,currentImageIndex, setCurrentImageIndex, scale, setScale,openModal
  const openModal = (index) => {
    setCurrentImageIndex(index);
    setScale(1); // Reset scale when opening modal
    setIsOpen(true);
  };

  // ==================== IMAGES VIEWER =====================


  return (
    <div>
      <style>
        {`
          body {
            font-family: Arial, sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            line-height: 1.6;
            color: #444;
          }
          .container {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            overflow: hidden;
            padding: 0; /* Remove padding */
          }
          .header {
            background-color: #218838;
            color: #fff;
            text-align: center;
            padding: 30px 0;
            border-bottom: 5px solid #1e7e34;
          }
          .header h1 {
            font-size: 2.5em;
            margin: 10px 0;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          }
          .content {
            padding: 20px;
            text-align: center;
            background-color: rgba(144, 238, 144, 0.5);
          }
          .content p {
            color: #666;
            margin-bottom: 20px;
            font-size: 1.1em;
          }
          .main-image {
            width: auto;
            max-width: 100%;
            border-radius: 1px;
            margin-top: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          .button {
            display: inline-block;
            padding: 12px 25px;
            margin-top: 20px;
            background-color: #28a745;
            color: white;
            text-decoration: none;
            font-size: 1.1em;
            border-radius: 5px;
            transition: background-color 0.3s ease;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
          }
          .button:hover {
            background-color: #218838;
            color: #fff;
          }
          .view-more {
            margin-top: 20px;
            text-align: center;
          }
          .view-more a {
            color: #28a745;
            text-decoration: none;
            font-size: 1.1em;
          }
          .footer {
            background-color: #333;
            color: #fff;
            text-align: center;
            padding: 20px;
            border-top: 1px solid #1e1e1e;
            font-size: 0.9em;
          }
          .footer a {
            color: #28a745;
            text-decoration: none;
          }
          .social-icons {
            margin: 20px 0;
          }
          .social-icons a {
            margin: 0 5%;
            display: inline-block;
          }
          .social-icons img {
            width: 30px;
            max-width: 100%;
            transition: transform 0.3s ease;
          }
          .social-icons a:hover img {
            transform: scale(1.2);
          }
        `}
      </style>

      {/* <div className='ps-5 ms-4'>
          <h6>Subject:<span>{instance?.subject}</span></h6>
        </div> */}

      <div className="container my-4 p-0">
        <div className="header">
          {/* <ImageModal src={logo} alt='logo'/> */}
          <img src={logo} alt="Logo" style={{ width: '15vw' }} />
          <h1>{instance?.title}</h1>
        </div>
        <div className="content">
          <ImageViewer 
            images={images}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            currentImageIndex={currentImageIndex}
            setCurrentImageIndex={setCurrentImageIndex}
            scale={scale}
            setScale={setScale}
          />
           <img src={instance?.image} alt="Tour " 
            onClick={() => openModal(0)} className="main-image" style={{cursor:'pointer'}}/>
          <p>
            {instance?.text}{' '}
            <a href={instance?.view_more_link} className="view-more-link">
              View More
            </a>
            .
          </p>
          <div className="views">
            <a href="https://package2-besh-tours-managing-app.vercel.app/inquiries/add" className="button">
              Book Now
            </a>
          </div>
        </div>
        <div className="footer">
          <div className="social-icons">
            <a href="https://www.facebook.com/beshafricanadventures?mibextid=ZbWKwL">
              <img
                src="https://img.icons8.com/ios-filled/50/1877F2/facebook-new.png"
                alt="Facebook"
              />
            </a>
            <a href="https://x.com/BeshAdventures">
              <img
                src="https://ik.imagekit.io/4rfgtafuc0/social%20media/x(white).png?updatedAt=1725557546866"
                alt="X"
              />
            </a>
            <a href="https://www.instagram.com/besh_african_adventures?igsh=MWd2ZjN4ZXJoOGYw">
              <img
                src="https://www.pngmart.com/files/21/Instagram-PNG-Isolated-File.png"
                alt="Instagram"
              />
            </a>
            <a href="https://www.linkedin.com/in/besh-african-adventures-174ba31b6/">
              <img
                src="https://img.icons8.com/ios-filled/50/0072b1/linkedin.png"
                alt="LinkedIn"
              />
            </a>
            <a href="https://www.youtube.com/@beshafricanadventures2324">
              <img
                src="https://img.icons8.com/ios-filled/50/ff0000/youtube-play.png"
                alt="YouTube"
              />
            </a>
          </div>
          <p>&copy; 2024 Besh African Adventures. All rights reserved.</p>
          <p>
            <a href="#/" style={{ color: '#28a745', textDecoration: 'none' }}>
              Unsubscribe
            </a>{' '}
            |{' '}
            <Link to="/pages/privacy-policy" style={{ color: '#28a745', textDecoration: 'none' }}>
              Privacy Policy
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Preview;
