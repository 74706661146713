import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaCog, FaSignOutAlt, FaClipboardList, FaQuestionCircle } from 'react-icons/fa';
import { AuthContext } from '../context/AuthContext';
import { MdEmail } from 'react-icons/md';
import { RiFileTextLine } from 'react-icons/ri';
import { AiOutlineSafety } from 'react-icons/ai';


export default function Dashboard() {
  const [isDashboardCollapsed, setIsDashboardCollapsed] = useState(true);
  const { logout } = useContext(AuthContext);

  const toggleDashboard = () => {
    setIsDashboardCollapsed(!isDashboardCollapsed);
  };

  return (
    <div
      className={`col-auto ${isDashboardCollapsed ? 'collapsed' : ''}`}
      style={{
        transition: 'width 0.5s',
        width: isDashboardCollapsed ? '3.5rem' : '10rem',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'lightgreen',
        height: '88vh',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        position: 'relative',
        msOverflowStyle: 'none',
      }}
    >
      <div
        className="p-3 fw-semibold cursor-pointer"
        onClick={toggleDashboard}
        style={{ textAlign: 'center', color: '#000000' }}
      >
        {isDashboardCollapsed ? <FaBars /> : <Link className='text-decoration-none text-dark fw-bold'>Dashboard</Link>}
      </div>
      <ul
        style={{
          flex: '1',
          listStyle: 'none',
          paddingLeft: '0',
          transition: '90s',
        }}
      >
        {!isDashboardCollapsed ? (
          <>
            <li className='ps-3' style={{ marginTop: '0.5rem', fontSize: '0.9rem' }}>
              <Link className='text-decoration-none text-dark fw-semibold' to="/templates"><RiFileTextLine /> Templates</Link>
            </li>
            <li className='ps-3' style={{ marginTop: '2rem', fontSize: '0.9rem' }}>
              <Link className='text-decoration-none text-dark fw-semibold' to="/emails"><MdEmail /> Emails</Link>
            </li>
            <li className='ps-3' style={{ marginTop: '2rem', fontSize: '0.9rem' }}>
              <Link className='text-decoration-none text-dark fw-semibold' to="/logs"><FaClipboardList /> Logs</Link>
            </li>
            <li className='ps-3' style={{ marginTop: '2rem', fontSize: '0.9rem' }}>
              <Link className='text-decoration-none text-dark fw-semibold' to="/settings"><FaCog /> Settings</Link>
            </li>
            <li className='ps-3' style={{ marginTop: '2rem', fontSize: '0.9rem' }}>
              <Link className='text-decoration-none text-dark fw-semibold' to="/faqs"><FaQuestionCircle /> FAQs</Link>
            </li>
            <li className='ps-3' style={{ marginTop: '2rem', fontSize: '0.9rem' }}>
              <Link className='text-decoration-none text-dark fw-semibold' to="/pages/privacy-policy"><AiOutlineSafety /> Privacy Policy</Link>
            </li>
            <li className='ps-3' style={{ marginTop: '2rem', fontSize: '0.9rem' }}>
              <Link onClick={logout} className='text-decoration-none text-danger fw-semibold'><FaSignOutAlt /> Logout</Link>
            </li>
          </>
        ) : (
          <>
            <li className='ps-3'>
              <Link className='text-decoration-none text-dark fw-medium' to="/templates"><RiFileTextLine style={{ marginTop: '2rem' }} /></Link>
            </li>
            <li className='ps-3'>
              <Link className='text-decoration-none text-dark fw-medium' to="/emails"><MdEmail style={{ marginTop: '2rem' }} /></Link>
            </li>
            <li className='ps-3'>
              <Link className='text-decoration-none text-dark fw-medium' to="/logs"><FaClipboardList style={{ marginTop: '2rem' }} /></Link>
            </li>
            <li className='ps-3'>
              <Link to="/settings" className='text-decoration-none text-dark fw-semibold'><FaCog style={{ marginTop: '2rem' }} /></Link>
            </li>
            <li className='ps-3'>
              <Link to="/faqs" className='text-decoration-none text-dark fw-semibold'><FaQuestionCircle style={{ marginTop: '2rem' }} /></Link>
            </li>
            <li className='ps-3'>
              <Link to="/pages/privacy-policy" className='text-decoration-none text-dark fw-semibold'><AiOutlineSafety style={{ marginTop: '2rem' }} /></Link>
            </li>
            <li className='ps-3'>
              <Link onClick={logout} className='text-decoration-none text-danger fw-semibold'><FaSignOutAlt style={{ marginTop: '2rem' }} /></Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
