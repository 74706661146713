import React, { createContext, useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/RequestsQueue'; // Import the request queue
import { LogContext } from '../../context/LogContext';

export const TemplateTwoContext = createContext();

export default function TemplateTwoProvider({ children }) {
  const authToken = localStorage.getItem('authToken')
  const [loading, setLoading] = useState(false);
  const [temp2Change, setTemp2Change] = useState(false);
  const [instances, setInstances] = useState([]);
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const [instance,setInstance] = useState({})
  const {fetchLogs} = useContext(LogContext)

  // ************************** CREATE **************************

  // Add an Instance
  function addTemp2Instance(formData) {
    setLoading(true);
    axiosInstance.requestWithQueue({
      method: 'POST',
      url: 'https://besh.newsletter.safarinetics.com/template2/add',
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (response.data.success) {
          setTemp2Change(!temp2Change);
          navigate(`/templates/2/templates/${response.data.id}`);
          Swal.fire({
            icon: 'success',
            text: response.data.success,
            position: 'top-end',
            timer: 24000,
            toast: true,
            showConfirmButton: false,
            background: '#228B22',
            color: 'wheat',
          });
          localStorage.removeItem('details');
        } else {
          Swal.fire({
            icon: 'warning',
            text: response.data.error || response.data.msg,
            position: 'top-end',
            timer: 24000,
            toast: true,
            showConfirmButton: false,
            background: 'red',
            color: 'wheat',
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  }

  // ************************ READ ************************

  useEffect(() => {
    const fetchInstances = async () => {
      setLoading(true);

      try {
        if (authToken) {
          const response = await axiosInstance.requestWithQueue({
            method: 'GET',
            url: 'https://besh.newsletter.safarinetics.com/template2/all',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
            },
          });

          setInstances(response.data);
        }
      } catch (error) {
        console.error('Error fetching template2 instances:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInstances();
  }, [temp2Change, authToken]);

  // ************************** UPDATE **************************

  // Update Instance
  function updateTemp2Instance(instanceId, formData) {
    setLoading(true);
    axiosInstance.requestWithQueue({
      method: 'PATCH',
      url: `https://besh.newsletter.safarinetics.com/template2/${instanceId}/update`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (response.data.success) {
          setTemp2Change(!temp2Change);
          navigate(`/templates/2/templates/${response.data.id}`);
          Swal.fire({
            icon: 'success',
            text: response.data.success,
            position: 'top-end',
            timer: 24000,
            toast: true,
            showConfirmButton: false,
            background: '#228B22',
            color: 'wheat',
          });
        } else {
          Swal.fire({
            icon: 'warning',
            text: response.data.error || response.data.msg,
            position: 'top-end',
            timer: 24000,
            toast: true,
            showConfirmButton: false,
            background: 'red',
            color: 'wheat',
          });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // ************************** DELETE **************************

  // Delete Instance
  function deleteTemp2Instance(instanceId) {
    setLoading(true);
    axiosInstance.requestWithQueue({
      method: 'DELETE',
      url: `https://besh.newsletter.safarinetics.com/template2/${instanceId}/delete`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (response.data.success) {
          setTemp2Change(!temp2Change);
          navigate(`/templates/2/instances`);
          Swal.fire({
            icon: 'success',
            text: response.data.success,
            position: 'top-end',
            timer: 24000,
            toast: true,
            showConfirmButton: false,
            background: '#228B22',
            color: 'wheat',
          });
        } else {
          Swal.fire({
            icon: 'warning',
            text: response.data.error || response.data.msg,
            position: 'top-end',
            timer: 24000,
            toast: true,
            showConfirmButton: false,
            background: 'red',
            color: 'wheat',
          });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // ************************** SENDING **************************

  // Test
  function testTemp2Instance(instanceId, email) {
    setLoading(true);
    axiosInstance.requestWithQueue({
      method: 'POST',
      url: `https://besh.newsletter.safarinetics.com/template2/${instanceId}/test`,
      data: { email },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.data.success) {
          setTemp2Change(!temp2Change);
          fetchLogs()
          Swal.fire({
            icon: 'success',
            text: response.data.success,
            position: 'top-end',
            timer: 24000,
            toast: true,
            showConfirmButton: false,
            background: '#228B22',
            color: 'wheat',
          });
        } else {
          Swal.fire({
            icon: 'warning',
            text: response.data.error || response.data.msg,
            position: 'top-end',
            timer: 24000,
            toast: true,
            showConfirmButton: false,
            background: 'red',
            color: 'wheat',
          });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // Send
  function sendTemp2Instance(instanceId) {
    setLoading(true);
    axiosInstance.requestWithQueue({
      method: 'POST',
      url: `https://besh.newsletter.safarinetics.com/template2/${instanceId}/send`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (response.data.success) {
          setTemp2Change(!temp2Change);
          fetchLogs()
          Swal.fire({
            icon: 'success',
            text: response.data.success,
            position: 'top-end',
            timer: 24000,
            toast: true,
            showConfirmButton: false,
            background: '#228B22',
            color: 'wheat',
          });
        } else {
          Swal.fire({
            icon: 'warning',
            text: response.data.error || response.data.msg,
            position: 'top-end',
            timer: 24000,
            toast: true,
            showConfirmButton: false,
            background: 'red',
            color: 'wheat',
          });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const contextData = {
    loading,
    setLoading,
    addTemp2Instance,
    temp2Change,
    setTemp2Change,
    instances,
    updateTemp2Instance,
    deleteTemp2Instance,
    testTemp2Instance,
    sendTemp2Instance,
    update,
    setUpdate,
    instance,setInstance,
  };

  return (
    <TemplateTwoContext.Provider value={contextData}>
      {children}
    </TemplateTwoContext.Provider>
  );
}
