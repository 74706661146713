import React, { useContext, useState } from 'react';
import backgroundImage from '../images/lion.png'; // Import your background image
import { AuthContext } from '../context/AuthContext';
import { motion } from 'framer-motion';
import LoadingComponent from '../components/LoadingComponent';

const ResetPassword = () => {
  const { resetPassword, loading, setLoading } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    resetPassword({name, email});
    setLoading(true)
  }
  if(loading){
    return(
      <LoadingComponent loadingText={'Authenticating details ...'} />
    )
  }

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <motion.div
        style={{
          textAlign: 'center',
          backgroundColor: 'rgba(5,15,27,0.8)', // 60% transparent white background
          padding: '40px',
          borderRadius: '10px',
          border: '1px solid #ccc',
          maxWidth: '400px',
          width: '80%',
        }}
        initial={{ opacity: 0}}
        animate={{ opacity: 1}}
        transition={{ duration: 1.5 }}
      >
        <h2 style={{ fontFamily: 'Arial, sans-serif', color: 'lightgreen', marginBottom: '30px' }}>Reset Password</h2>
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: '20px' }}>
            <input
              required
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Name"
              style={{
                borderRadius: '5px',
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc',
              }}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <input
              required
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email"
              style={{
                borderRadius: '5px',
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc',
              }}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            style={{
              width: '100%',
              borderRadius: '5px',
              padding: '10px',
              fontSize: '1rem',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
            }}
            disabled={loading}
          >
            {loading ? <Spinner /> : 'Submit'}
          </button>
        </form>
        <p className='text-info' style={{ marginTop: '20px', fontSize: '0.9rem' }}>A new password will be sent to your email.</p>
      </motion.div>
    </div>
  );
};

// Assuming Spinner is a component that renders a loading spinner
const Spinner = () => (
  <div className="spinner-border text-warning" role="status">
    <span className="sr-only"></span>
  </div>
);

export default ResetPassword;