import React from 'react';
import logo from '../images/logo.png';


const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light shadow border" style={{ backgroundColor:'lightgrey' }}>
      <div className="container-fluid d-flex justify-content-between align-items-center">
        {/* Logo */}
        <img  loading="lazy" src={logo} alt="Logo" style={{ maxHeight: '10vh' }} />

        {/* Title */}
        <span className="navbar-brand mx-auto" style={{ fontFamily: 'inknut antiqua', fontWeight: 'bold', fontSize: 'auto' }}>
          Besh African Adventures
        </span>
      </div>
    </nav>
  );
};

export default Navbar;