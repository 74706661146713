import React, { useContext, useEffect, useState } from "react";
import Emails from '../emails/Emails'
import { MdClose } from "react-icons/md";
import { LogContext } from "../context/LogContext";
import LogDetails from "./LogDetails";

const LogSummaryDetails = ({summaryLog}) => {
   const {logs} = useContext(LogContext)
  const [showModal, setShowModal] = useState(false);
  const [logDetails, setLogDetails] = useState([])

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if(logs && summaryLog){
      setLogDetails(findContributingDicts(summaryLog, logs))
    }
  }, [logs, summaryLog])
  
  
  function findContributingDicts(aggDict, originalDicts) {
   const matches = [];
   for (const original of originalDicts) {
       // Check if the original dictionary matches the relevant keys in the aggregate
       if (original.email_template === aggDict.email_template &&
           original.email_type === aggDict.email_type &&
           original.template_id === aggDict.template_id &&
           original.subject === aggDict.subject) {
            matches.push(original);
           
       }
   }
   if (matches.length > aggDict.recipients){
    const match = matches.filter((i) => i.time_sent === aggDict.time_sent)
    
    return match
   }
   return matches;
}


  return (
    <div style={{ padding: "20px", textAlign: "center",position:'relative' }}>
      {/* Button to trigger modal */}
      <button
        type="button"
        className="btn btn-outline-light btn-sm"
        style={{ position: "absolute",top: "0",left: "10%", cursor:'pointer'}}
        onClick={handleOpenModal}
      >
        Details
      </button>

      {/* Modal */}
      {showModal && (
        <div
          className="modal show"
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: "1050",
          }}
        >
          <div
            className="modal-dialog bg-secondary"
            style={{
              margin: "100px auto",
              maxWidth: "1000px",
              overflow:'auto',
              width:'auto',
            }}
          >
            <div className="modal-content">
              {/* <div className="modal-header">
                <h5 className="modal-title text-dark">Modal Title</h5>
              </div> */}
              <div className="modal-body text-dark" style={{position:"relative"}}>
              <button
                  type="button"
                  className="close"
                  onClick={handleCloseModal}
                  style={{position:"absolute", border: "none", background: "none", right:5 }}
                >
                  <span className="fw-bold"><MdClose color='red' size={30}/></span>
                </button>
                <div className="d-flex flex-wrap gap-4 align-items-center mt-3">
                  <h5>Template:<span className="fst-italic">{summaryLog?.email_template}</span></h5>
                  <h6 >Subject:<span className="fst-italic">{summaryLog?.subject}</span></h6>
                </div>
                <LogDetails logs={logDetails} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogSummaryDetails;
