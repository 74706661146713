// src/App.js
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './layout/Layout';
import Landing from './components/Landing';
import Unauthorised from './components/Unauthorised';
import Login from './auth/Login';
import ResetPassword from './auth/ResetPassword';
import ResetChangePassword from './auth/ResetChangePassword';
import AuthProvider from './context/AuthContext';
import EmailProvider from './context/EmailContext';
import TemplateOneProvider from './templates/Temp1/TemplateOneContext';
import LogProvider from './context/LogContext';
import ChangePassword from './settings/ChangePassword';
import Emails from './emails/Emails';
import Setting from './settings/Setting';
import Templates from './templates/Templates';
import Instances from './templates/Temp1/Instances';
import AddInstance from './templates/Temp1/AddInstance';
import InstanceDetails from './templates/Temp1/InstanceDetails';
import Logs from './logs/Logs';
import InstancesTwo from './templates/temp2/Instances';
import AddInstanceTwo from './templates/temp2/AddInstance';
// import TemplateTwoProvider from './context/TemplateTwoContext';
import InstanceDetailsTwo from './templates/temp2/InstanceDetails';
import PrivacyPolicy from './components/PrivacyPolicy';
import FAQs from './components/FAQs';
import AllTemplates from './templates/AllTemplates';
import Template1Routes from './templates/Temp1/Template1Routes';
import Template2Routes from './templates/temp2/Template2Routes';

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <EmailProvider>
            <LogProvider>
              <Routes>
                <Route path='/' element={<Layout />}>
                  <Route index element={<Landing />} />
                  <Route path='*' element={<Unauthorised />} />
                  <Route path='/pages/privacy-policy' element={<PrivacyPolicy />} />
                  <Route path='/auth/login' element={<Login />} />
                  <Route path='/auth/password/reset' element={<ResetPassword />} />
                  <Route path='/auth/password/:email/change' element={<ResetChangePassword />} />
                  <Route path='/emails' element={<Emails />} />
                  
                  <Route path='/templates' element={<AllTemplates />}>
                    <Route index element={< Templates/>} />
                    <Route path='1/*' element={<Template1Routes />} />
                    <Route path='2/*' element={<Template2Routes />} />

                    <Route path='2/instances' element={<InstancesTwo />} />
                    <Route path='2/instances/add' element={<AddInstanceTwo />} />
                    <Route path='2/instances/:instanceId' element={<InstanceDetailsTwo />} />
                  </Route>
                  <Route path='/settings' element={<Setting />} />
                  <Route path='/settings/auth/password/change' element={<ChangePassword />} />
                  <Route path='/logs' element={<Logs />} />
                  <Route path='/faqs' element={<FAQs />} />
                </Route>
              </Routes>
            </LogProvider>
        </EmailProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
