import React, { useContext, useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs'; 
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { TemplateOneContext } from './TemplateOneContext';
import DeleteInstance from './DeleteInstance';
import LoadingComponent from '../../components/LoadingComponent';
import TestInstance from './TestInstance';
import axiosInstance from '../../utils/RequestsQueue'; // Adjust the import path if necessary

export default function InstanceDetails() {
  const authToken = localStorage.getItem('authToken')
  // const { authToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const { instanceId } = useParams();
  // const [] = useState({});
  const {instance, setInstance, fetchInstances,temp1Change, sendTemp1Instance, loading, setLoading } = useContext(TemplateOneContext);
  const [update, setUpdate] = useState(false)
  useEffect(() => {
    if (authToken && instanceId) {
      setLoading(true);
      axiosInstance.requestWithQueue({
        method: 'GET',
        url: `https://besh.newsletter.safarinetics.com/template1/${instanceId}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(response => {
          setInstance(response.data);
          setUpdate(false);
        })
        .catch(error => {
          console.error('Error fetching instance details:', error);
         
        })
        .finally(()=> setLoading(false));
    }
  }, [instanceId, authToken, temp1Change, setLoading, setUpdate, setInstance]);

  if (loading) {
    return (
      <LoadingComponent loadingText={'Loading ....'} />
    );
  }

  return (
    <div className="container-fluid my-4 shadow-lg">
      <div className="card">
        <div className="card-header bg-dark text-white">
          <div className="d-flex align-items-center justify-content-center">
            <h3 className="mb-0 ">{instance?.subject}</h3>
          </div>
        </div>
        <div className="card-body">
          {/* Header Bar with Buttons */}
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
            <div>
              <button onClick={() => { 
                fetchInstances()
                navigate('/templates/1/templates');
              }} className="btn btn-outline-primary btn-sm mr-2">
                <BsArrowLeft /> Instances
              </button>
            </div>
            <div className="d-flex flex-wrap">
              {!instance?.sent && (
                <>
                {!update ? (<button
                className='btn btn-sm btn-primary mx-1'
                 onClick={()=> {
                  navigate(`/templates/1/templates/${instanceId}/update`)
                  setUpdate(true)
                }}>Update</button>
                ):(
                  <button
                  className='btn btn-sm btn-primary mx-1'
                   onClick={()=> {
                    navigate(`/templates/1/templates/${instanceId}`)
                    setUpdate(false)
                  }}>View</button>
                )}
                  
                  <button onClick={() => sendTemp1Instance(instanceId)} className="btn btn-primary btn-sm mx-1">Send</button>
                  <DeleteInstance />
                </>
              )}
              <TestInstance />
            </div>
          </div>
          <div className="row">
            <Outlet/>
          </div>
        </div>
      </div>
    </div>
  );
}
