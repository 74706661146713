import React from 'react'
import { motion } from 'framer-motion';
import MainCard from './Temp1/MainCard';
import MainCard2 from './temp2/MainCard2';

export default function Templates() {
  return (
    <div style={{height:'100%'}} className='container-fluid'>
      <div>
         <h2 className='text-center py-2'>Email Templates</h2>
      </div>
      <motion.div className="conatiner row"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}>
          <MainCard/>
          <MainCard2/>
             
        </motion.div>
         
     <motion.div
     className='text-center'
     initial={{ opacity: 0, y: 50 }}
     animate={{ opacity: 1, y: 0 }}
     transition={{ duration: 1.05, delay: 0.9 }}
     >
      <p>If you need more templates please contact <span className='text-info'>access@safarinetics.co.ke</span></p>
     </motion.div>
    </div>
  )
}
