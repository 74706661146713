import React from 'react'
import { motion } from 'framer-motion';
import image from './template1.png'
import { useNavigate } from 'react-router-dom';

export default function MainCard() {

  const navigate = useNavigate()
  return (
    <motion.div
            className="col-lg-4 col-md-4 col-sm-4 mb-4 px-4"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            onClick={() => navigate('/templates/1/templates')}
          >
            <div className="card border-success" style={{ backgroundColor:'rgb(5,15,27,0.8)', overflow: 'hidden',height:"20em" }}>
              <motion.img
                // src={`data:${flight?.mime_type};base64,${flight.image}`}
                src = {image}
                className="card-img-top img-fluid"
                style={{height:"100%"}}
                alt={'template'}
                initial={{ scale: 1.2 }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              />
              <div className="card-footer" style={{ position: 'absolute', bottom: 0, width: '100%', backgroundColor:'rgb(5,15,27,0.8)' }}>
                  <h5 className="card-title text-center  fw-medium" style={{ color:'lightgreen' }}>Template 1</h5>
              </div>
            </div>
          </motion.div>
  )
}
