import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import logo from '../images/sn_logo.png'; // Replace with your logo file path
import backgroundImage from '../images/lion.png'; // Replace with your background image file path

export default function Landing() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem('authToken')

  // Uncomment this if you want to navigate after a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      // localStorage.removeItem('authToken');
      if (authToken) {
        navigate('/templates');
      } else {
        navigate('/auth/login');
      }
    }, 8000);

    // Cleanup the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [authToken, navigate]);
  

  return ( 
  <AnimatePresence>
  <motion.div
    className="container-fluid d-flex flex-column justify-content-center align-items-center"
    style={styles.container}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0, y: -50, transition: { duration: 1.5 } }}
  >
    <motion.h1
        className="display-4 mb-4 text-center"
        style={styles.title}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2.5}}
      >
        NEWSLETTER APP
      </motion.h1>
      <motion.div
        className="d-flex justify-content-center align-items-center mb-4"
        style={styles.logoContainer}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 3.5 }}
      >
        <motion.img
          src={logo}
          alt="Company Logo"
          style={styles.logo}
          className="img-fluid"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2, delay: 0.5 }}
        />
      </motion.div>
    
      </motion.div>
    </AnimatePresence>
  );
}

const styles = {
  container: {
    height: '100%',
    padding: '20px',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'InkNuts Antiqua, serif',
    fontWeight: 600,
    color: 'rgb(255, 211, 100)',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.97)',
    textTransform: 'uppercase',
  },
  logoContainer: {
    backgroundColor: 'rgba(5, 15, 27, 0.5)',
    borderRadius: '10px',
    padding: '4px',
    maxWidth: '80vw',
  },
  logo: {
    height: 'auto',
    width: '100%',
    maxWidth: '350px',
  },
};
