import React from 'react'
import { AiFillBehanceCircle, AiOutlineCheck, AiOutlineClose, AiTwotoneCloseCircle } from 'react-icons/ai'
import { FaCheck } from 'react-icons/fa6'
import { MdCancel, MdCheck } from 'react-icons/md'

export default function LogDetails({logs}) {
  return (
    <div style={{overflow:'auto',width:'100%'}}>
   <table className="table table-bordered ">
   <thead>
     <tr>
       <th>#</th>
       <th>Recipient</th>
       <th>Sender</th>
       <th>Opened</th>
       <th>Link(last)</th>
     </tr>
   </thead>
   <tbody>
     {logs && logs.length > 0 && logs.map((log,index) => (
       <tr key={log.id}>
         <td>{index + 1}</td>
         <td>{log.recipient}</td>
         <td>{log.sender}</td>
         <td>{log.is_opened ? <MdCheck color='green' size={20}/>:<MdCancel color='red' size={20}/>}</td>
         <td>{log?.last_clicked_link || 'None'} </td>
       </tr>
     ))}
   </tbody>
 </table>
 </div>
  )
}
