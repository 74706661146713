import React from 'react';
import TawkTo from './TawkTo';

const FAQs = () => {
  const faqData = [
    {
      question: "What is the purpose of this Newsletter app?",
      answers: [
         "This Newsletter app allows tour and travel agents to create, generate, and send personalized emails to their clients efficiently.",
         "This Newsletter app allows tour and travel agents to create, generate, and send personalized emails to their clients efficiently."
      ]
    },
    {
      question: "How do I create an email instance?",
      answers: [
         "After logging in, navigate to the 'Templates' section from the Dashboard.",
         "Select the Template which you want to create.",
         "Click the last card with a Create new instance button and fill the form .",
         "Ensure you fill in all the required fields before submitting."
      ]
    },
    {
      question: "How Do I add an email",
      answers: [
        "Navigate to emails from the dashboard",
        "Click on the 'Add Email button on the top left'",
        "Fill the form that pops up with the email address you wish to add and then click on submit button",
        "NOTE : The onboarding Email will not be sent to the address because you added manually. The email is sent to the ones added through the official website footer."
      ]
    },
    {
      question: "How to unsubscribe",
      answers: [
        "You can click on the Subscribe button on the emails table",
        "The clients can unsubscribe by clicking on unsubscribe link in the emails footer"
      ]
    },
    {
      question: "Can emails be duplicated?",
      answers: [
        "No, The email address will be resubscribed if unsubscribed."
      ]
    },
    {
      question: "Can I send the same email instance multiple times?",
      answers: [
        "No, once an email instance has been sent, it cannot be sent again to ensure unique communication with your clients."
      ]
    },
    {
      question: "How can I test my email before sending it?",
      answers: [
         "You can test your email instance multiple times before sending it by using the 'Normal Test' feature."
      ]
    },
    {
      question: "Is there a limit on spam testing?",
      answers: [
        "Yes, you can test the spam score of your email instance up to three times within a 24-hour period."
      ]
    },
    {
      question: "What should I do if I forget my password?",
      answers: [
        "Click the 'Forgot Password?' link on the login page, and follow the instructions to reset your password."
      ]
    },
    {
      question: "Can I change my password?",
      answers: [
        "Yes, once logged in, go to the 'Settings' section in the Dashboard to change your password."
      ]
    },
    {
      question: "Are there any restrictions on email content?",
      answers: [
        "Yes, please ensure your email content complies with anti-spam regulations and best practices to avoid being flagged as spam.",
        "Your emails should not contain misleading subject lines or false information about the content of the message.",
        "Avoid using excessive capitalization, exclamation marks, or spammy phrases like 'free' among others that may trigger spam filters.",
        "Do not include any illegal content, such as hate speech, harassment, or explicit material.",
        "Avoid using deceptive or misleading links that could harm the recipient's experience."
      ]
    },
    {
      question: "What happens if my email is marked as spam?",
      answers: [
        "If your email is marked as spam, it may affect your sender reputation, and we recommend reviewing your content and testing it again."
      ]
    },
    {
      question: "Is my data secure?",
      answers: [
        "Yes, we prioritize your data security and use industry-standard encryption to protect your information."
      ]
    },
    {
      question: "Can I access the app on mobile devices?",
      answers: [
        "Yes, our web app is optimized for mobile use, allowing you to manage your email communications on the go."
      ]
    },
    {
      question: "How can I contact support if I have issues?",
      answers: [
        "You can contact our support team through the 'Help' section in the app or by emailing info@safarinetics.com."
      ]
    }
  ];

  const styles = {
    faqs: {
      padding: '20px',
      backgroundColor: '#f9f9f9'
    },
    faqItem: {
      marginBottom: '10px'
    },
    summary: {
      cursor: 'pointer',
      fontSize: '18px',
      fontWeight: 'bold',
      color: 'orange'
    },
    answer: {
      fontSize: '16px',
      color: 'beige',
      margin: '5px 0 0 0'
    },
    answerList: {
      listStyleType: 'square',
      paddingLeft: '20px'
    }
  };

  return (
    <div style={styles.faqs}>
      <TawkTo/>
      <h2 className='text-center fw-bold' style={{fontFamily:'junge',color:'forestgreen'}}
      >Frequently Asked Questions (FAQs)</h2>
      {faqData.map((faq, index) => (
        <details className='container m-4 p-4 rounded shadow ' key={index} style={{backgroundColor:'rgb(54,69,79)', border:'2px solid gold'}}>
          <summary className='' style={styles.summary}>{index+1}. {faq.question}</summary>
          <ul style={styles.answerList}>
            {faq.answers.map((answer, idx) => (
              <li key={idx} style={styles.answer}>{answer}</li>
            ))}
          </ul>
        </details>
      ))}
    </div>
  );
};

export default FAQs;
