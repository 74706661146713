import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

export default function Setting() {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  return (
    <div className="container-fluid" style={{ padding: '20px', backgroundColor: '#f0f0f0', minHeight: '100vh' }}>
      <div style={{ textAlign: 'center', marginBottom: '40px' }}>
        <h2 style={{ 
          fontFamily: 'Inknut Antiqua, serif', 
          marginBottom: '10px', 
          color: '#333', 
          fontSize: 'clamp(1.8rem, 3vw, 2.5rem)'  // Dynamic font size
        }}>
          Settings
        </h2>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '30px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto',
        top: '50%', 
      }}>
        <h4 style={{ 
          fontFamily: 'Arial, sans-serif', 
          fontSize: 'clamp(1.2rem, 2vw, 1.8rem)',  // Dynamic font size
          color: '#007bff', 
          marginBottom: '15px' 
        }}>
          Profile Information
        </h4>
        <p style={{ 
          fontFamily: 'Arial, sans-serif', 
          fontSize: 'clamp(1rem, 1.8vw, 1.2rem)',  // Dynamic font size
          marginBottom: '10px', 
          color: '#555' 
        }}>
          Name: <strong>{currentUser?.name}</strong>
        </p>
        <p style={{ 
          fontFamily: 'Arial, sans-serif', 
          fontSize: 'clamp(1rem, 1.8vw, 1.2rem)',  // Dynamic font size
          marginBottom: '10px', 
          color: '#555' 
        }}>
          Email: <strong>{currentUser?.email}</strong>
        </p>
        <p style={{ 
          fontFamily: 'Arial, sans-serif', 
          fontSize: 'clamp(1rem, 1.8vw, 1.2rem)',  // Dynamic font size
          marginBottom: '10px', 
          color: '#555' 
        }}>
          Registered On: <strong>{currentUser?.registered_at}</strong>
        </p>
        <p style={{ 
          fontFamily: 'Arial, sans-serif', 
          fontSize: 'clamp(1rem, 1.8vw, 1.2rem)',  // Dynamic font size
          marginBottom: '10px', 
          color: '#555' 
        }}>
          Last Updated: <strong>{currentUser?.updated_at}</strong>
        </p>
        <button
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            marginTop: '20px',
            fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',  // Dynamic font size
          }}
          onClick={() => navigate('/settings/auth/password/change')}
        >
          Change Password
        </button>
      </div>
    </div>
  );
}
