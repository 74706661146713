import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from '../utils/RequestsQueue'; // Import the axiosInstance with the request queue

export const LogContext = createContext();

export default function LogProvider({ children }) {
  const authToken = localStorage.getItem('authToken')
  const [logs, setLogs] = useState([]);
  const [logsSummary, setLogsSummary] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to fetch logs
  const fetchLogs = async () => {
    if (authToken) {
      setLoading(true); // Start loading

      try {
        const response = await axiosInstance.requestWithQueue({
          url: 'https://besh.newsletter.safarinetics.com/logs/all',
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        });

        // Set logs if no error
        if (!response.data.error && response.data.length >= logs.length) {
          setLogs(response.data);
        }
      } catch (error) {
        console.error('Error fetching logs:', error);
      } finally {
        setLoading(false); // End loading
      }
    }
  };

  // Fetch logs on component mount and every 3 minutes
  useEffect(() => {
    // Fetch logs initially
    fetchLogs();

    // Set up interval to fetch logs every 3 minutes
    const intervalId = setInterval(fetchLogs, 3 * 60 * 1000); // 3 minutes in milliseconds

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [authToken]);

  // =================== LOGS SUMMARY ===================
  function getLogsSummary(logs) {
    const emailSummaryLive = {};
    const testList = [];

    logs.forEach((log) => {
      const key = `${log.email_template}-${log.email_type}-${log.template_id}`;

      if (log.email_type === 'live' && log.email_template !== ('Subscription' || 'Password Reset')) {
        if (!emailSummaryLive[key]) {
          emailSummaryLive[key] = {
            email_template: log.email_template,
            email_type: log.email_type,
            opens: 0,
            recipients: 0,
            subject: log.subject,
            template_id: log.template_id,
            time_sent: log.time_sent,
          };
        }

        const entry = emailSummaryLive[key];
        if (log.is_opened) {
          entry.opens += 1;
        }
        entry.recipients += 1;
      } else {
        const testDict = {
          email_template: log.email_template,
          email_type: log.email_type,
          opens: log.is_opened ? 1 : 0,
          recipients: 1,
          subject: log.subject,
          template_id: log.template_id,
          time_sent: log.time_sent,
        };
        testList.push(testDict);
      }
    });

    const emailSummaryList = Object.values(emailSummaryLive).concat(testList);
    emailSummaryList.sort((a, b) => new Date(b.time_sent) - new Date(a.time_sent));

    return emailSummaryList;
  }

  useEffect(() => {
    if (logs && logs.length > 0) {
      setLogsSummary(getLogsSummary(logs));
    }
  }, [logs]);

  // =================== TIME CONVERTER TO AM/PM ===========================
  function formatToCustomDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${day}/${month}/${year} ${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
  }

  const contextData = {
    setLogs,
    fetchLogs,
    logs,
    loading,
    setLoading,
    setLogsSummary,
    logsSummary,
    formatToCustomDate,
  };

  return (
    <LogContext.Provider value={contextData}>
      {children}
    </LogContext.Provider>
  );
}
