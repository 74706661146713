import React, { useContext } from 'react';
import { MdRefresh } from 'react-icons/md';
import { EmailContext } from '../context/EmailContext';

export default function RefreshEmails() {
  const {fetchEmails} = useContext(EmailContext)

  

  return (
    <button
      onClick={() => fetchEmails()}
      title='refresh'
      className="btn btn-primary btn-sm"
     
    >
      <MdRefresh size={20} /> {/* Display the icon with a suitable size */}
    </button>
  );
}
