import React, { useContext } from 'react'
import Swal from 'sweetalert2';
import { EmailContext } from '../context/EmailContext';
import { FaPlus } from 'react-icons/fa6';

export default function AddEmail() {
  const {addEmail} = useContext(EmailContext)

  function handleAdd() {
    Swal.fire({
      title: "Input email address",
      input: "email",
      inputLabel: "Your email address",
      inputPlaceholder: "Enter your email address",
      background: 'rgb(8, 50, 32,0.81)',
      color: '#B79F00',
      confirmButtonText:'Add email'
    }).then((result) => {
      if (result.value) {
        const email = result.value;
        addEmail(email)
      }
    });
  }
  return (
    <button title='Add Email' onClick={handleAdd} className='btn btn-primary btn-sm my-3'><FaPlus/></button>
  )
}
