import React from 'react';
import '../styles/Loading.css'; // Make sure to import the CSS file
import logo from '../images/logo.png'; // Import your logo image

const LoadingComponent = ({ loadingText }) => {
  return (
      <div id="loading-backdrop" className='rounded'>
        <div id="loading-container">
          <img className='img-fluid' src={logo} alt="Loading..." id="loading-logo" />
          <span id="loading-text">{loadingText}</span>
        </div>
      </div>
    
  );
};

export default LoadingComponent;