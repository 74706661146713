import React from 'react';

const PrivacyPolicy = () => {
  const containerStyle = {
    padding: '20px',
    margin: '20px auto',
    borderRadius: '1px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
  };

  const sectionTitleStyle = {
    borderBottom: '2px solid #ddd',
    paddingBottom: '10px',
    marginBottom: '20px'
  };

  const emailLinkStyle = {
    color: '#007bff',
    textDecoration: 'none'
  };

  return (
    <div className="container bg-light" style={containerStyle}>
      <h1 className="text-center mb-4" style={{ color: '#343a40', fontFamily:'junge' }}>Privacy Policy</h1>
      
      <div className="mb-4">
        <h2 style={sectionTitleStyle}>Introduction</h2>
        <p>
          Welcome to Besh African Adventures! We respect your privacy and are committed to safeguarding your personal information.
          This Privacy Policy explains how we collect, use, and protect your data when you subscribe to our email communications.
        </p>
      </div>
      
      <div className="mb-4">
        <h2 style={sectionTitleStyle}>Information We Collect</h2>
        <h5>Email Addresses:</h5>
        <p>We collect email addresses when you subscribe to our newsletters or promotional emails.</p>
      </div>
      
      <div className="mb-4">
        <h2 style={sectionTitleStyle}>How We Use Your Information</h2>
        <p>
          We use your email address solely for the purpose of sending you relevant content, updates, and special offers related to Besh African Adventures. 
          We do not share your email with third parties.
        </p>
      </div>
      
      <div className="mb-4">
        <h2 style={sectionTitleStyle}>Opt-Out Options</h2>
        <h5>Unsubscribe Link:</h5>
        <p>Every email we send includes an “unsubscribe” link in the footer. Clicking this link will remove you from our mailing list.</p>
        <h5>Direct Contact:</h5>
        <p>
          You can also contact us directly at <a href="mailto:info@beshafricanadventures.co.ke" style={emailLinkStyle}>info@beshafricanadventures.co.ke</a> 
          to request removal from our email list.
        </p>
      </div>
      
      <div className="mb-4">
        <h2 style={sectionTitleStyle}>Data Security</h2>
        <p>We take data security seriously. Your email address is stored securely, and we implement measures to prevent unauthorized access.</p>
      </div>
      
      <div>
        <h2 style={sectionTitleStyle}>Contact Us</h2>
        <p>If you have any questions or concerns about our privacy practices, please reach out to us at 
          <a href="mailto:info@beshafricanadventures.co.ke" style={emailLinkStyle}> info@beshafricanadventures.co.ke</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
