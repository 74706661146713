import React, { useContext } from 'react';
import { MdRefresh } from 'react-icons/md';
import { LogContext } from '../context/LogContext';

export default function RefreshLogs() {
const {fetchLogs}= useContext(LogContext)
 

  return (
    <button
      onClick={()=> fetchLogs()}
      title='refresh'
      className="btn btn-primary btn-sm"
    >
      <MdRefresh size={20} /> {/* Display the icon with a suitable size */}
    </button>
  );
}
