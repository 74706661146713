import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, TextField, Button, Grid, Box, Paper } from '@mui/material';
import { TemplateOneContext } from './TemplateOneContext';
import { useParams } from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent'
import Swal from 'sweetalert2';
import { uploadImage } from '../../components/ImageUploader';



export default function UpdateInstance() {
   const {UpdateTemp1Instance, loading, setLoading,instance} = useContext(TemplateOneContext)
   const {instanceId} = useParams()
   const [formData, setFormData] = useState(instance);

  useEffect(() => {
    // Prefill the form with the initial data
    setFormData(instance);
  }, [instance]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    
    // Create an object for JSON data
    const updatedData = {
        subject: formData.subject,
        title: formData.title,
        text: formData.text,
        view_more_link: formData.view_more_link
    };

    // Check if formData.image is a valid file
    if (formData.image instanceof File) {
        try {
            const result = await uploadImage(formData.image);
            
            // Add image URL and ID to the JSON object
            updatedData.image = result.url; 
            updatedData.image_id = result.id; 
        } catch (error) {
            console.error("Error uploading image:", error);
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error || "An error occurred during image upload.",
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                color: 'wheat',
                background: 'red'
            });
            return; // Terminate the function if there is an error
        }
    } else {
        // If image is not a File, directly use the existing image URL and ID
        updatedData.image = formData.image;
        updatedData.image_id = formData.image_id;
    }

    // Proceed with updating the instance, sending JSON data instead of FormData
    try {
      console.log(updatedData)
        await UpdateTemp1Instance(instanceId, updatedData); // Send JSON data
        setLoading(true);
    } catch (error) {
        console.error('Error updating instance:', error);
        // Handle submission error
    }
};


  if (loading){
    return (
      <LoadingComponent loadingText={'Updating ....'}/>
    )
  }

  return (
    <Container maxWidth="md" sx={{ mt: 0 }}>
      <Paper elevation={3} sx={{ p: 4, backgroundColor: '#C0C0C0' }}>
        <Typography variant="h4" align="center" gutterBottom sx={{ mb: 2 }}>
          Update
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="subject"
                label="Subject"
                variant="outlined"
                fullWidth
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="title"
                label="Title"
                variant="outlined"
                fullWidth
                value={formData.title}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="text"
                label="Text"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={formData.text}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="imageFile">Image File</label>
              <input
                type="file"
                id="imageFile"
                accept=".png, .jpg, .jpeg"
                style={{ display: 'none' }}
                onChange={handleImageChange}
              />
              <label htmlFor="imageFile">
                <Button variant="outlined" component="span">
                  Upload New Image
                </Button>
              </label>
              {formData.image && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Current Image: {formData.image.name || <a href={formData.image} target="_blank" rel="noopener noreferrer">View</a>}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="view_more_link"
                label="View More URL"
                variant="outlined"
                fullWidth
                value={formData.view_more_link}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Button type="submit" variant="contained" color="primary">
                  Update
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
