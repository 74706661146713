import React, {useContext, useState} from 'react';
import { Link } from 'react-router-dom';
import ImageViewer from '../../utils/ImageViewer';
import { TemplateTwoContext } from './TemplateTwoContext';

const Preview = () => {
  const logo = 'https://beshafricanadventures.co.ke/wp-content/uploads/2024/05/Besh-Logo-Transparent-01-e1716311550879.png';
 const {instance} = useContext(TemplateTwoContext)
    // ==================== IMAGES VIEWER =====================
    const images=[
      instance?.head_image,
      instance?.sub1_image,
      instance?.sub2_image,
      instance?.sub3_image
    ]
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [scale, setScale] = useState(1);
    // isOpen, setIsOpen,currentImageIndex, setCurrentImageIndex, scale, setScale,openModal
    const openModal = (index) => {
      setCurrentImageIndex(index);
      setScale(1); // Reset scale when opening modal
      setIsOpen(true);
    };
  
    // ==================== IMAGES VIEWER =====================

  return (
    <div>
      <style>
        {`
          body {
            font-family: 'Arial', sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
            line-height: 1.6;
            color: #444;
          }
          .container {
            width: 100%;
            max-width: 700px;
            margin: 0 auto;
            padding: 0;
            background-color: rgba(144,238,144,0.61);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            overflow: hidden;
          }
          .header {
            background: url('${instance?.head_image}') no-repeat center center/cover;
            min-height: 250px;
            height:fit-content;
            position: relative;
            text-align: center;
            padding: 30px 20px;
            text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
          }
          .header .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(10, 10, 0, 0.016);
            z-index: 1;
          }
          .header-content {
            position: relative;
            z-index: 2;
          }
          .header-content img {
            height: 100px;
            animation: imageFadeIn 2s;
          }
          .header-content h1 {
            margin: 10px 0;
            font-size: 2.5em;
            font-weight: bold;
            animation: fadeIn 2s;
            color: wheat;
            text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
          }
          .header-content p {
            font-size: 1.3em;
            color: whitesmoke;
            animation: fadeIn 2s;
            text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
          }
          .main-content {
            padding: 20px 30px;
          }
          .main-content h2 {
            color: #333;
            font-size: 2em;
            margin-bottom: 10px;
          }
          .main-content p {
            color: #666;
            margin-bottom: 20px;
          }
          .main-content .button {
            display: inline-block;
            padding: 12px 25px;
            margin-top: 20px;
            background-color: #28a745;
            color: #fff;
            text-decoration: none;
            font-size: 1.1em;
            border-radius: 5px;
            transition: background-color 0.3s ease;
            text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
          }
          .main-content .button:hover {
            background-color: #218838;
          }
          .section {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 20px 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #e4e4e4;
          }
          .section-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          }
          .section-content h2 {
            color: #28a745;
            font-size: 1.8em;
            margin-bottom: 10px;
            animation: fadeIn 2s;
            text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
          }
          .section-content img {
            width:auto;
            max-width: 100%;
            height: auto;
            border-radius: 8px;
            margin-bottom: 20px;
            animation: imageFadeIn 2s;
            transition: transform 0.3s ease;
          }
          .section-content img:hover {
            transform: scale(1.05);
          }
          .section-content p {
            color: #666;
            font-size: 1.1em;
            animation: fadeIn 2s;
          }
          .wavy-line {
            width: 100%;
            height: 50px;
            margin: 0;
          }
          .footer {
            background-color: #333;
            color: #fff;
            text-align: center;
            padding: 20px;
            border-top: 1px solid #e4e4e4;
          }
          .footer a {
            color: #28a745;
            text-decoration: none;
          }
          .footer p {
            margin: 5px 0;
            font-size: 0.9em;
          }
          .social-icons {
            margin: 20px 0;
          }
          .social-icons a {
            margin: 0 10px;
            display: inline-block;
          }
          .social-icons img {
            width: 30px;
          }
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes imageFadeIn {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          @media (max-width: 768px) {
            .header-content h1 {
              font-size: 2em;
            }
            .header-content p {
              font-size: 1.1em;
            }
            .main-content h2 {
              font-size: 1.8em;
            }
            .main-content .button {
              padding: 10px 20px;
              font-size: 1em;
            }
            .section {
              flex-direction: column;
              align-items: center;
            }
            .section img, .section-content {
              width: 100%;
              text-align: center;
            }
            .section img {
              margin-bottom: 10px;
            }
          }
          @media (max-width: 480px) {
            .header {
              height: 200px;
            }
            .header-content h1 {
              font-size: 1.5em;
            }
            .header-content p {
              font-size: 1em;
            }
            .main-content h2 {
              font-size: 1.5em;
            }
            .main-content p {
              font-size: 1em;
            }
            .main-content .button {
              padding: 8px 16px;
              font-size: 0.9em;
            }
            .footer p {
              font-size: 0.8em;
            }
          }
        `}
      </style>
      {/* <h6 className='ps-5 pt-4'>Subject :{instance?.subject}</h6> */}
      <div className="container my-4">
        <div className="header">
          <div className="overlay"></div>
          <div className="header-content">
            <img src={logo} style={{width:'15vw'}} alt="logo" />
            <h1>{instance?.head_title}</h1>
            <p>{instance?.head_text}</p>
          </div>
        </div>
        <div className="main-content">
          <h2>{instance?.main_title}</h2>
          <p>{instance?.main_text}</p>
          <div className="section">
            <div className="section-content">
              <h2>{instance?.sub1_title}</h2>
              <ImageViewer 
                images={images}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                scale={scale}
                setScale={setScale}
              />
              <img src={instance?.sub1_image} onClick={()=> openModal(1)} alt="Tour" />
              <p>{instance?.sub1_text}</p>
            </div>
          </div>
          <div className="section">
            <div className="section-content">
              <h2>{instance?.sub2_title}</h2>
              <ImageViewer 
                images={images}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                scale={scale}
                setScale={setScale}
              />
              <img src={instance?.sub2_image} onClick={()=> openModal(2)} alt="Tour" />
              <p>{instance?.sub2_text}</p>
            </div>
          </div>
          <div className="section">
            <div className="section-content">
              <h2>{instance?.sub3_title}</h2>
              <ImageViewer 
                images={images}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                scale={scale}
                setScale={setScale}
              />
              <img src={instance?.sub3_image} onClick={() => openModal(3)} alt="Tour" />
              <p>{instance?.sub3_text}</p>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <a href={instance?.view_more_link} className="button">View More</a>
            <a href="https://package2-besh-tours-managing-app.vercel.app/inquiries/add" className="button">Book Now</a>
          </div>
        </div>
        <div className="footer">
        <div className="social-icons">
        <a href="https://www.facebook.com/beshafricanadventures?mibextid=ZbWKwL">
        <img
                    src="https://img.icons8.com/ios-filled/50/1877F2/facebook-new.png"
                    alt="Facebook"
                  />
        </a>
        <a href="https://x.com/BeshAdventures">
        <img 
        src="https://ik.imagekit.io/4rfgtafuc0/social%20media/x(white).png?updatedAt=1725557546866"
        alt="X"
        />
        </a>
        <a href="https://www.instagram.com/besh_african_adventures?igsh=MWd2ZjN4ZXJoOGYw">
        <img
                    src="https://www.pngmart.com/files/21/Instagram-PNG-Isolated-File.png"
                    alt="Instagram"
                  />
        </a>
        <a href="https://www.linkedin.com/in/besh-african-adventures-174ba31b6/">
        <img
                    src="https://img.icons8.com/ios-filled/50/0072b1/linkedin.png"
                    alt="LinkedIn"
                  />
        </a>
        <a href="https://www.youtube.com/@beshafricanadventures2324">
        <img
                    src="https://img.icons8.com/ios-filled/50/ff0000/youtube-play.png"
                    alt="YouTube"
                  />
        </a>
        </div>
        <p>© 2024 Besh African Adventures. All rights reserved.</p>
        <p>
        <a href="" style={{ color: '#28a745', textDecoration: 'none' }}>
        Unsubscribe
        </a>{' '}
        |{' '}
        <Link to="/pages/privacy-policy" style={{ color: '#28a745', textDecoration: 'none' }}>
        Privacy Policy
        </Link>
        </p>
        </div>
      </div>
    </div>
);
};

export default Preview;