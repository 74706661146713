import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { TemplateOneContext } from './TemplateOneContext'

export default function DeleteInstance() {
   const {instanceId} = useParams()
   const {deleteTemp1Instance} = useContext(TemplateOneContext)


   function handleDelete(){
      Swal.fire({
         title: "Are you sure?",
         text: "You won't be able to revert this!",
         icon: "warning",
         color:'wheat',
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Yes, delete!",
         background:'rgb(255, 0, 0,0.8)', // change the colorcode ,
         toast:true
       }).then((result) => {
         if (result.isConfirmed) {
            deleteTemp1Instance(instanceId)
         }
       });
   }
  return (
   <button onClick={handleDelete} className="btn btn-sm btn-danger mr-2">Delete</button>
  )
}
