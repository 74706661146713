import React from 'react'
import { Route, Routes } from 'react-router-dom'
import TemplateTwoProvider from './TemplateTwoContext'
import Template from './Template'
import Instances from './Instances'
import AddInstance from './AddInstance'
import InstanceDetails from './InstanceDetails'
import Preview from './Preview'
import UpdateInstance from './UpdateInstance'

export default function Template2Routes() {
  return (
   <TemplateTwoProvider>
      <Routes>
         <Route path='/templates' element={<Template/>}>
         <Route index element={<Instances/>} />
         <Route path='add' element={<AddInstance />} />
         <Route path=':instanceId' element={<InstanceDetails />}>
            <Route index element={<Preview />} />
            <Route path='update' element={<UpdateInstance/>} />
         </Route>
         </Route>
      </Routes>
 </TemplateTwoProvider>
  )
}
