import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import LoadingComponent from '../../components/LoadingComponent';
import { TemplateTwoContext } from './TemplateTwoContext';

export default function Instances() {
  const navigate = useNavigate();
  const {instances, loading} = useContext(TemplateTwoContext)
  // const [] = useState(true)


  return (
    <div className="container-fluid py-4" style={{ backgroundColor: 'rgba(1, 50, 32, 0.0)', minHeight: '100%' }}>
    <h2 className="text-center mb-4" style={{ color: 'green', fontFamily: 'junge' }}>
      Template 2 Instances
    </h2>
    {loading ? (
      <LoadingComponent loadingText={'Loading Instances'} />
    ) : (
      <motion.div
        className="row"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2.5 }}
      >
        {Array.isArray(instances) && instances.length > 0 ? (
          instances.map((instance, index) => (
            <motion.div
              key={instance.id}
              className="col-lg-4 col-md-4 col-sm-6 mb-4 px-4 "
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.2 * index }}
            >
              <div className="card border-secondary shadow-lg" style={{ backgroundColor: 'rgba(175, 238, 238, 0.8)', overflow: 'hidden', height: '20em' }}>
                <motion.img
                  src={instance?.head_image}
                  onClick={() => navigate(`/templates/2/templates/${instance?.id}`)}
                  className="card-img-top img-fluid"
                  style={{ height: '100%' }}
                  alt={instance.subject}
                  initial={{ scale: 1.2 }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                />
                <div className="card-footer" style={{ position: 'absolute', bottom: 0, width: '100%',backgroundColor: 'rgb(5,15,27,0.68)' }}>
                  <small className="card-title text-center text-light fst-italic">{instance.subject}</small><br/>
                  <small className="card-title text-center text-light fst-italic">Created : {instance.created_at}</small>
                </div>
              </div>
            </motion.div>
          ))
        ) : (
          <motion.div
            className="col-lg-4 col-md-4 col-sm-6 mb-4 px-4"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.2 * instances.length }}
          >
            <div className="card border-secondary shadow-lg" style={{ backgroundColor: 'rgb(5,15,27,0.68)', height: '20em' }}>
              <div className="card-body d-flex justify-content-center align-items-center">
                <button
                  onClick={() => navigate('add')}
                  className="btn btn-outline-info"
                >
                  New Instance
                </button>
              </div>
            </div>
          </motion.div>
        )}
        
        {/* New Instance Button */}
        <motion.div
          className="col-lg-4 col-md-4 col-sm-6 mb-4 px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.2 * instances.length }}
        >
          <div className="card border-secondary shadow-lg" style={{ backgroundColor: 'rgb(5,15,27,0.468)', height: '20em' }}>
            <div className="card-body d-flex justify-content-center align-items-center">
              <button
                onClick={() => navigate('add')}
                className="btn btn-outline-info"
              >
                New Instance
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    )}
  </div>

  );
}
