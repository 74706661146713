import React from 'react';
import { Route, Routes } from 'react-router-dom';
import TemplateOneProvider from './TemplateOneContext'; // Context provider for Template 1
import Template from './Template';
import Instances from './Instances';
import InstanceDetails from './InstanceDetails';
import AddInstance from './AddInstance';
import Preview from './Preview';
import UpdateInstance from './UpdateInstance';

export default function Template1Routes() {
  return (
    <TemplateOneProvider>
      <Routes>
        <Route path='/templates' element={<Template />}>
          <Route index element={<Instances />} />
          <Route path='add' element={<AddInstance />} />
          <Route path=':instanceId' element={<InstanceDetails />}>
            <Route index element={<Preview />} />
            <Route path='update' element={<UpdateInstance />} />
          </Route>
        </Route>
      </Routes>
    </TemplateOneProvider>
  );
}
