import { useEffect } from 'react';

const TawkTo = () => {
  useEffect(() => {
    // Initialize Tawk.to script
    const tawkScript = document.createElement('script');
    tawkScript.type = 'text/javascript';
    tawkScript.async = true;
    tawkScript.src = 'https://embed.tawk.to/664dd87d9a809f19fb33a687/1hug1baog';
    tawkScript.charset = 'UTF-8';
    tawkScript.setAttribute('crossorigin', '*');

    // Append the script to the body
    document.body.appendChild(tawkScript);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(tawkScript);
    };
  }, []);

  return null; // This component does not render anything
};

export default TawkTo;
